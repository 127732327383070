<template>
  <div class="reasons" v-scrollanimation>
    <div class="container">
      <div class="reasons__inner flex f-space-between">
        <div class="reasons__left">
          <h2 class="reasons__title headline-7 w700 left-to-right">
            4 причины заказать разработку приложения у нас
          </h2>
          <div class="reasons__list">
            <div
              class="reasons__item bottom-to-top"
              :class="`delay-${index + 2 * index}`"
              v-for="(item, index) in reasons"
              :key="index"
            >
              <div
                class="reasons__head flex f-space-between align-items-center"
              >
                <h3
                  class="headline-3 w700 left-to-right"
                  :class="`delay-${index + 3 * index}`"
                >
                  {{ item.title }}
                </h3>
                <button
                  class="flex f-center left-to-right"
                  :class="`delay-${index + 4 * index}`"
                >
                  <svg
                    width="1.75rem"
                    height="1.75rem"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.9163 6.55078L21.8995 13.9996L14.9163 21.4485L13.9587 20.5508L19.4852 14.6559H7V13.3434H19.4852L13.9587 7.44845L14.9163 6.55078Z"
                      fill="#3772FF"
                    />
                  </svg>
                </button>
              </div>
              <p
                class="reasons__text caption-3 left-to-right"
                :class="`delay-${index + 4 * index}`"
              >
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
        <div class="reasons__right">
          <div class="reasons__banner">
            <svg
              width="34.38rem"
              height="31.88rem"
              viewBox="0 0 550 510"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="bottom-to-top delay-4"
            >
              <g filter="url(#filter0_f_1560_47882)">
                <circle cx="292" cy="218" r="82" fill="#B028A9" />
              </g>
              <defs>
                <filter
                  id="filter0_f_1560_47882"
                  x="0"
                  y="-74"
                  width="584"
                  height="584"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="105"
                    result="effect1_foregroundBlur_1560_47882"
                  />
                </filter>
              </defs>
            </svg>
            <svg
              width="36.5rem"
              height="36.5rem"
              viewBox="0 0 584 584"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="bottom-to-top delay-6"
            >
              <g filter="url(#filter0_f_1560_47881)">
                <circle cx="292" cy="292" r="82" fill="#282BC5" />
              </g>
              <defs>
                <filter
                  id="filter0_f_1560_47881"
                  x="0"
                  y="0"
                  width="584"
                  height="584"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="105"
                    result="effect1_foregroundBlur_1560_47881"
                  />
                </filter>
              </defs>
            </svg>
            <img
              class="reasons__banner bottom-to-top delay-3"
              src="@/assets/images/reasons_banner.jpg"
              alt="banner"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const reasons = [
  {
    title: "Максимум устройств",
    text: "Приложение будет одинаково и стабильно работать на различных устройствах",
  },
  {
    title: "Оформление заказа",
    text: "Мы максимально облегчаем этот процесс для пользователя",
  },
  {
    title: "Максимальное удобство",
    text: "Мы проектируем приложение с интуитивно понятным интерфейсом и навигацией для пользователей",
  },
  {
    title: "Быстродействие",
    text: "Загрузка приложений максимально ускорена за счет снижения веса картинок и скриптов",
  },
];
</script>
<style lang="scss" scoped>
.reasons {
  overflow: hidden;
  &__inner {
    padding: 5.63rem 0;
    gap: 2rem;
  }

  &__left {
    max-width: 31.25rem;
  }

  &__title {
    margin-bottom: 3.75rem;
  }

  &__head {
    margin-bottom: 0.5rem;

    button {
      background: none;
    }
  }

  &__text {
    color: var(--secondary-2);
  }

  &__item {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 0.06rem solid var(--primary-2);
  }

  &__banner {
    position: relative;
    width: 35rem;
    height: 30rem;

    svg {
      position: absolute;

      &:nth-child(1) {
        top: -6rem;
        right: -6rem;
      }

      &:nth-child(2) {
        bottom: -9rem;
        left: -7rem;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1336px) {
  .reasons {
    &__inner {
      padding: 5rem 0;
    }

    &__banner {
      width: 30rem;
      height: 25rem;
    }

    &__left {
      font-size: 12px;
    }

    &__list {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 992px) {
  .reasons {
    &__banner {
      width: 25rem;
      height: 20rem;
    }

    &__left {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .reasons {
    &__left {
      font-size: 8px;
    }

    &__banner {
      width: 20rem;
      height: 15rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .reasons {
    &__inner {
      flex-direction: column-reverse;
      align-items: center;
      padding: 3rem 0;
    }

    &__banner {
      width: 100%;
      height: auto;
    }
  }
}
</style>
