<template>
  <div class="steps" v-scrollanimation>
    <div class="container">
      <div class="steps__inner">
        <h2 class="steps__title headline-2 w700 left-to-right">
          Этапы разработки сайта
        </h2>
        <div class="steps__wrap">
          <div
            class="steps__step bottom-to-top"
            v-for="(step, index) in steps"
            :key="step"
            :class="`delay-${index + 3 * index}`"
          >
            <h3 class="steps__type headline-5">{{ step.type }}</h3>
            <ul class="steps__list body-3 w400">
              <li class="steps__item" v-for="item in step.list" :key="item">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";

defineProps({
  background: Boolean,
});

const steps = [
  {
    type: "Согласование целей и задач",
    list: [
      "Определяем задачу, которую должен решать сайт",
      "Анализируем конкурентов",
      "Анализируем особенноти целевой аудитории",
    ],
  },
  {
    type: "Прототип и дизайн",
    list: [
      "Выбираем прототип сайта",
      "Определяемся с дизайном",
      "Подготавливаем визуальную часть",
    ],
  },
  {
    type: "Верстка дизайна",
    list: [
      "Верстаем визуальную часть сайта",
      "Подгоняем вид сайта под дизайн",
      "Оптимизируем код для дальнейшего программирования",
    ],
  },
  {
    type: "Прогаммирование",
    list: [
      "Первичное тестирование системы",
      "Тестирование внешних систем сайта",
      "Правка и доработка",
    ],
  },
  {
    type: "А\\Б - тестирование",
    list: [
      "Верстаем визуальную часть сайта",
      "Подгоняем вид сайта под дизайн",
      "Оптимизируем код для дальнейшего программирования",
    ],
  },
  {
    type: "Запуск",
    list: [
      "Проводим конечную проверку",
      "Запускаем сайт",
      "Техническая поддержка сайта",
    ],
  },
];
</script>
<style lang="scss">
.steps {
  position: relative;

  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    margin-bottom: 6.25rem;
  }

  &__step {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2.5rem;
    margin-bottom: 1.38rem;
    border-bottom: 0.06rem solid var(--primary-2);
  }

  &__item {
    margin-bottom: 0.13rem;
  }
}

@media screen and (max-width: 1336px) {
  .steps {
    &__inner {
      padding: 5rem 0;
    }

    &__title {
      margin-bottom: 3.5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .steps {
    &__inner {
      padding: 3rem 0;
    }

    &__title {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }

    &__step {
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__type {
      margin-bottom: 1rem;
      line-height: 1.3;
    }

    &__list {
      padding-left: 1.5rem;
    }

    &__item {
      line-height: 1.4;
    }

    &__wrap {
      font-size: 14px;
    }
  }
}
</style>
