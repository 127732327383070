<template>
  <div class="industries" v-scrollanimation>
    <div class="container">
      <div class="industries__inner">
        <h2 class="industries__title headline-2 w700 left-to-right">Отрасли</h2>
        <div class="industries__wrap">
          <div
            class="industries__item bottom-to-top"
            v-for="(item, index) in industries"
            :class="`delay-${index + 2 * index}`"
            :key="item"
          >
            <div class="industries__icon flex f-center">
              <svg
                width="2rem"
                height="2rem"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.5 10.8889C5.5 8.35284 7.48924 6.25 10 6.25C12.0476 6.25 13.7483 7.64854 14.3061 9.54004C14.8358 9.35222 15.4057 9.25 16 9.25C16.5943 9.25 17.1642 9.35222 17.6939 9.54004C18.2517 7.64854 19.9524 6.25 22 6.25C24.5108 6.25 26.5 8.35284 26.5 10.8889C26.5 12.3983 25.7953 13.7543 24.6954 14.6048C27.0909 15.6831 28.75 18.1541 28.75 21H27.25C27.25 17.9518 24.874 15.5278 22 15.5278V14.0278C23.6314 14.0278 25 12.6484 25 10.8889C25 9.12939 23.6314 7.75 22 7.75C20.5641 7.75 19.3317 8.81866 19.057 10.2746C20.3139 11.2232 21.125 12.7413 21.125 14.4444C21.125 16.2092 20.2542 17.7753 18.9188 18.7151C21.7566 19.8859 23.75 22.7125 23.75 26H22.25C22.25 22.4757 19.4407 19.6389 16 19.6389C12.5593 19.6389 9.75 22.4757 9.75 26H8.25C8.25 22.7125 10.2434 19.8859 13.0812 18.7151C11.7458 17.7753 10.875 16.2092 10.875 14.4444C10.875 12.7413 11.6861 11.2232 12.943 10.2746C12.6683 8.81866 11.4359 7.75 10 7.75C8.36862 7.75 7 9.12939 7 10.8889C7 12.6484 8.36862 14.0278 10 14.0278V15.5278C7.12598 15.5278 4.75 17.9518 4.75 21H3.25C3.25 18.1541 4.90913 15.6831 7.30463 14.6048C6.2047 13.7543 5.5 12.3983 5.5 10.8889ZM16 10.75C14.0091 10.75 12.375 12.3929 12.375 14.4444C12.375 16.496 14.0091 18.1389 16 18.1389C17.9909 18.1389 19.625 16.496 19.625 14.4444C19.625 12.3929 17.9909 10.75 16 10.75Z"
                  fill="white"
                />
              </svg>
            </div>
            <h3 class="industries__subtitle headline-3 w700">
              {{ item }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const industries = [
  "Сложные проекты",
  "Новости",
  "Интернет магазин",
  "Такси",
  "Кафе\\Ресторан",
  "Доставка",
];
</script>
<style lang="scss" scoped>
.industries {
  position: relative;

  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    margin-bottom: 4.38rem;
  }

  &__icon {
    width: 3.75rem;
    height: 3.75rem;
    border: 0.06rem solid var(--secondary-1);
    border-radius: 1rem;
    margin-bottom: 1.5rem;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2.5rem;
    grid-row-gap: 4.38rem;
  }
}

@media screen and (max-width: 1336px) {
  .industries {
    &__inner {
      padding: 5rem 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .industries {
    &__inner {
      padding: 3rem 0;
    }

    &__title {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 475px) {
  .industries {
    &__wrap {
      display: grid;
      font-size: 14px;
      grid-gap: 3rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
